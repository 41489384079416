<template>
    <section>
    </section>
</template>
<script>
    import OrdenesService from '../../../services/ordenes.service'
    import facturacionStore from '../../../store/facturacion.store'
    import { computed, onMounted, ref, watch } from 'vue'
    import dayjs from 'dayjs'
    export default {
      name: 'facturacionMasivaEventoCapita',
      setup () {
        // Services
        const _OrdenesService = ref(new OrdenesService())
        // References
        const offset = ref(0)
        const page = ref(0)
        const limit = ref(10)
        const totalItemsCount = ref(0)
        const listadoOrdenes = ref([])
        const ordenesSeleccionadas = ref()
        const ordenesFacturar = ref([])
        const displayModal = ref(false)
        // Computed
        const filtros = computed(() => facturacionStore.getters._filtros)
        const params = computed(() => {
          return {
            offset: offset.value,
            page: page.value,
            limit: limit.value,
            filters: filtros.value
          }
        })
        // Data
        const listarOrdenesVenta = () => {
          _OrdenesService.value.evento(params.value).then(({ data }) => {
            totalItemsCount.value = data.total
            listadoOrdenes.value = data.rows
          })
        }
        // Methods
        const checkOrden = () => {
          ordenesFacturar.value = []
          for (const entrega of listadoOrdenes.value) {
            if (ordenesSeleccionadas.value.length) {
              for (const orden of ordenesSeleccionadas.value) {
                if (orden.DocNumOrden === entrega.DocNumOrden) {
                  if (ordenesFacturar.value.length) {
                    if (orden.DocNumEntrega !== entrega.DocNumEntrega) {
                      ordenesFacturar.value.push({
                        DocNum: entrega.DocNumOrden,
                        DocTotal: entrega.DocTotal,
                        _key: ordenesFacturar.value.length,
                        baseEntrys: entrega.BaseEntry
                      })
                      ordenesSeleccionadas.value.push(entrega)
                    }
                  } else {
                    ordenesFacturar.value.push({
                      DocNum: entrega.DocNumOrden,
                      DocTotal: entrega.DocTotal,
                      _key: ordenesFacturar.value.length,
                      baseEntrys: entrega.BaseEntry
                    })
                  }
                }
              }
            }
          }
          console.log('estas son las ordenes checkadas--->', ordenesSeleccionadas.value)
        }
        const openModal = (id) => {
          console.log('this id --->', id)
          displayModal.value = true
        }
        const onPage = ({ first, page, pageCount, rows }) => {
          limit.value = rows
          listarOrdenesVenta(params.value)
        }
        watch(filtros, (n, v) => {
          if (n) {
            listarOrdenesVenta()
          }
        })
        onMounted(() => {
          listarOrdenesVenta()
        })
        return {
          filtros,
          offset,
          limit,
          page,
          listadoOrdenes,
          ordenesSeleccionadas,
          totalItemsCount,
          dayjs,
          displayModal,
          openModal,
          onPage,
          checkOrden,
          ordenesFacturar
        }
      }

    }
</script>
<style scoped>
:global(.swal2-container) {z-index: 1000000 !important;}
</style>
