import { createStore } from 'vuex'
import dayjs from 'dayjs'
const facturacionStore = {
    state: {
        filtros: {
            versionIntranet: false,
            CodBodega: [],
            CodCliente: [],
            mantenerOrden: false,
            DocNumOrden: [],
            MotivoAutorizacion: '',
            Regimen: '',
            U_PHR_CodMunicipio: '',
            DocDate: [
              dayjs().format('YYYY-MM-DD'),
              dayjs().format('YYYY-MM-DD')
            ],
            buscar: 0,
            limpiar: false
        },
        listadoRegimen: [],
        listadoRegimenF: [],
        dataFacturaCapita: {
          MesCapita: '',
          comentario_factura: '',
          U_PHR_RegimenF: '',
          localizacion: {}
        },
        dataFacturarEventoCapita: {
          localizacion: {},
          mes: '',
          comentario: ''
        },
        comoCapita: false,
        abrirMenuCapita: false,
        abrirMenuEventoCapita: false,
        abrirMenuConvalor: false
    },
    getters: {
        _filtros: state => state.filtros,
        _listadoRegimen: state => state.listadoRegimen,
        _listadoRegimenF: state => state.listadoRegimenF,
        _dataFacturaCapita: state => state.dataFacturaCapita,
        _comoCapita: state => state.comoCapita,
        _abrirMenuCapita: state => state.abrirMenuCapita,
        _dataFacturarEventoCapita: state => state.dataFacturarEventoCapita,
        _abrirMenuEventoCapita: state => state.abrirMenuEventoCapita,
        _abrirMenuConvalor: state => state.abrirMenuConvalor
    },
    mutations: {
        setFiltros (state, payload) {
          // console.log('payload--->', payload)
          state.filtros = payload
        },
        setListadoRegimen (state, payload) {
          state.listadoRegimen = payload
        },
        setListadoRegimenF (state, payload) {
          state.listadoRegimenF = payload
        },
        setDataFacturaCapita (state, payload) {
          state.dataFacturaCapita = {
            MesCapita: payload.MesCapita,
            comentario_factura: payload.comentario_factura,
            U_PHR_RegimenF: payload.U_PHR_RegimenF,
            localizacion: payload.localizacion
          }
        },
        setComoCapita (state, payload) {
          state.comoCapita = payload
        },
        setAbrirMenuCapita (state, payload) {
          state.abrirMenuCapita = payload
        },
        setDataFacturarEventoCapita (state, payload) {
          state.dataFacturarEventoCapita = {
            localizacion: payload.localizacion,
            mes: payload.mes,
            comentario: payload.comentario
          }
        },
        setAbrirMenuEventoCapita (state, payload) {
          state.abrirMenuEventoCapita = payload
        },
        setAbrirMenuConvalor (state, payload) {
          state.abrirMenuConvalor = payload
        }
    },
    actions: {}
 }
const store = createStore(facturacionStore)
export default {
    namespaced: true,
    ...store
}
