import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_FACTURACION
export default class OrdenesVentaService {
  evento (params) {
    return http.get(`${baseUrl}/ordenes_venta/evento`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  capita (params) {
    return http.get(`${baseUrl}/ordenes_venta/capita`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  entregas (params) {
    return http.get(`${baseUrl}/ordenes_venta/entregas`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  medicamentosEntrega (params) {
    return http.get(`${baseUrl}/ordenes_venta/detalleEntregas`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
